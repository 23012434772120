import dateformat from 'dateformat'

export function getTime(): number {
  return Math.floor(+ new Date() / 1000)
}

export function dateForTime(time: number): Date {
  return new Date(time * 1000)
}

export function dateString(date: Date): string {
  return dateformat(date, 'HH:MM dd.mm.yyyy')
}
